<template>
  <div class="text-center">
    <template v-if="checkPermission('UnitResource.PUT.Unit')">
      <button
        @click="editUnit()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission('UnitResource.DELETE.Unit.id')">
      <button
        @click="deleteUnit()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const UnitRepository = RepositoryFactory.get("unit");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  props: {
    unitVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
    deleteUnit() {
      var vx = this;

      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Data Unit akan terhapus.",
        swalType: "warning",
        onConfirmButton: function () {
          blockUI.blockPage();
          vx.delete();
        },
        onCancelButton: function () {
          blockUI.unblockPage();
        }
      });
    },
    async delete() {
      var vx = this;

      await UnitRepository.deleteUnit(this.rowData.id)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.unitVue.fetch(resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Unit berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data PIC gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    editUnit() {
      this.unitVue.dataEdit = this.rowData;
      this.unitVue.showModal();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
};
</script>
