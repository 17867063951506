<template>
  <div>
    <base-header base-title="Unit"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                :table-name="tableName"
                :on-refresh="fetch"
              ></auto-refresh>&nbsp;&nbsp;
              <template v-if="checkPermission('UnitResource.POST.Unit')">
                <base-button-modal
                  :button-text="modalText"
                  :button-icon="modalIcon"
                  :button-name="modalName"
                  :onButtonModalClicked="showModal"
                ></base-button-modal>
              </template>
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body"
          v-if="isLoaded"
        >
          <data-tables :table="table"></data-tables>
        </div>
        <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <form-modal
          v-if="isShowModal"
          :show-modal.sync="isShowModal"
          @event="fetch"
          :modal-name="modalName"
          :modal-data="dataEdit"
        ></form-modal>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const UnitRepository = RepositoryFactory.get("unit");

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    BaseButtonModal,
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(import(/* webpackChunkName: "modal-unit" */ "./_components/FormModal.vue"));
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      isLoaded: false,
      isShowModal: false,
      dataEdit: null,
      headTitle: "Unit",
      tableName: "table_unit",
      modalText: "Tambah Unit",
      modalName: "modal_unit",
      modalIcon: "flaticon2-add-1",
      table: {
        tableName: "table_unit",
        tableDataResult: [],
        tableColumnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: -2,
            className: "text-center",
            render: function (t, e, a, n) {
              var i = {
                true: {
                  title: "Ya",
                  class: "kt-badge--success"
                },
                false: {
                  title: "Tidak",
                  class: "kt-badge--danger"
                }
              };
              return void 0 === i[t]
                ? t
                : '<span class="kt-badge ' +
                i[t].class +
                ' kt-badge--inline kt-badge--pill">' +
                i[t].title +
                "</span>";
            }
          },
          {
            defaultContent: "",
            targets: "_all"
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableColumns: [
          {
            title: "ID",
            data: "id"
          },
          {
            title: "Display Name 1",
            data: "displayName1"
          },
          {
            title: "Display Name 2",
            data: "displayName2"
          },
          {
            title: "Waktu Rata-Rata",
            data: "averageWaitTimeMinute"
          },
          {
            title: "Poli",
            data: function(row, type) {
                            if(row.tujuanRujukan) {
                                if(row.tujuanRujukan.nama){
                                    return row.tujuanRujukan.nama
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Tampilkan di Kiosk",
            data: "displayInKiosk"
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  unitVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableFilter: [
          {
            title: "Display Name 1",
            type: "text"
          },
          {
            title: "Display Name 2",
            type: "text"
          },
          {
            title: "Waktu Rata-Rata",
            type: "text"
          },
          {
            title: "Poli",
            type: "combobox"
          },
          {
            title: "Tampilkan di Kiosk",
            type: "boolean"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      }
    };
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.dataEdit = null;
      }
    }
  },
  methods: {
    showModal: function () {
      this.isShowModal = true;
    },
    async fetch(resolve) {
      new Promise((resolve, reject) => {
        UnitRepository.get({}, resolve, reject);
      }).then(response => {
        const data = response.data;
        if (this.isLoaded) {
          var datatable = $("#" + this.tableName).DataTable();
          datatable.clear();
          datatable.rows.add(data);
          datatable.draw("full-hold");
          if (resolve !== undefined) {
            resolve(data);
          }
        } else {
          this.table.tableDataResult = data;
          this.isLoaded = true;
        }
      }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('Unit : ' + msg);
      })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    }
  },
  created() {
    this.fetch();
  }
};
</script>
